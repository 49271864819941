import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/blogpost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`If you haven't been living under a rock for the past few years, you'll likely know that React is one of the dominant front-end frameworks of the current era. As of when this article was written (late 2020), React was the `}<a parentName="p" {...{
        "href": "https://insights.stackoverflow.com/survey/2020#technology-web-frameworks"
      }}>{`second most popular`}</a>{` front end framework behind jQuery and gaining steam. Written by a team at Facebook, it's a pretty cool new approach to web frameworks in the quickly accelerating rate of web development. It's built on a virtual DOM and makes changes based on DOM diffing , so it's really fast on switching views.`}</p>
    <p>{`React's design philosophy is based on the idea that everything is a component, which is simply a reusable bit of code that can create a displayable element or control the app in some way (or both). A React app is made by combining and nesting components, which can be made up of more components or React's base elements which roughly mirror the displayable HTML elements.`}</p>
    <p>{`Components are normally written in `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/jsx-in-depth.html"
      }}>{`JSX`}</a>{` (short for Javascript XML), which is a non-standard extension of Javascript syntax invented by developers at Facebook as an easier way to write React. This syntax is the same as XML syntax in that it has a tag name, attributes, and children, and essentially serves as syntactic sugar for React's `}<inlineCode parentName="p">{`createElement`}</inlineCode>{` method, which also takes a tag name, properties, and children as arguments`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div className="greetings">
    <p style={{color: "red"}}>Hello!</p>
    <p>Goodbye...</p>
</div>
 
// turns into...
 
React.createElement('div', {className: "greetings"}, [
        React.createElement('p', {style: {color: "red"}}, "Hello!"),
        React.createElement('p', null, "Goodbye...")
    ]
);
`}</code></pre>
    <p>{`JSX is definitely cleaner and a lot more elegant to write in, but since this syntax is not standard to Javascript, it needs to be preprocessed and turned into standard calls to the React library that `}<em parentName="p">{`can`}</em>{` be understood by a Javascript runtime.`}</p>
    <p>{`In order to set up a project where we can use JSX for React, we'll use three popular Javascript libraries: React, Babel, and Webpack. There's a number of script out there that will create a React project for you, like `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` which offers options for all kinds of extras and advanced configurations, but if you're like me, you want to figure out how to do it yourself. So let's get into it!`}</p>
    <h2>{`So What's Really Going On?`}</h2>
    <p>{`In this instructional, we're going to stack some JS libraries together. Our base will be Webpack, which is a Javascript module bundler. Essentially, Webpack begins with an entry file, which it traverses, following each function call and `}<inlineCode parentName="p">{`import`}</inlineCode>{` and `}<inlineCode parentName="p">{`require`}</inlineCode>{` to build an internal dependency graph, which maps all the requisite code that the entry file needs to run. Then it processes only the needed code and bundles it together in a single Javascript file which can be sent to a client to run on a website or application.`}</p>
    <p>{`A key feature of Webpack is its loaders, which essentially can be set to preprocess imported files before being bundled. We're going to use a loader which runs through a powerful Javascript transpiler called Babel in order to translate our code. Babel can be set to transpile a number of different syntaxes to a standardized target syntax (e.g. ES5, which is supported by the `}<a parentName="p" {...{
        "href": "https://caniuse.com/es5"
      }}>{`vast majority`}</a>{` of browsers in use today). It can convert Javascript-based languages like Typescript, Clojurescript, or Coffeescript, as well as translate extended syntaxes like JSX to different target libraries like React, Inferno, Preact, or even `}<a parentName="p" {...{
        "href": "https://medium.com/variant-as/using-jsx-for-your-own-lightweight-declarative-ui-library-a773d3796475"
      }}>{`your own`}</a>{`.`}</p>
    <p>{`So you might wonder, why use Webpack at all if Babel is the one converting JSX to React? Can't we just use Babel by itself? And the answer is yes, you can. Babel has a `}<a parentName="p" {...{
        "href": "https://babeljs.io/docs/en/babel-cli"
      }}>{`CLI`}</a>{` package which allows us to process files on a one-off basis. But this approach suffers from a lack of scalability — for any project that uses more than a few files, using the CLI becomes a non-trivial task. Not to mention that in a web app development context, the code we send to the user should be bundled and optimized to minimize its size, as well as be compatible with the most browsers possible. Using Babel as a loader in a bundling tool like Webpack provides us with the intrinsic benefits that such a tool provides, as well as the ability to easily add new types of web development tools and languages, so it's an all-around much better strategic approach for most projects.`}</p>
    <h2>{`Putting it Together`}</h2>
    <blockquote>
      <p parentName="blockquote">{`These instructions assume you have Node and npm installed on your machine. You can check by typing `}<inlineCode parentName="p">{`node --version`}</inlineCode>{` and `}<inlineCode parentName="p">{`npm --version`}</inlineCode>{` respectively at the command line, and you should get something like `}<inlineCode parentName="p">{`v12.10.1`}</inlineCode>{` and `}<inlineCode parentName="p">{`6.0.0`}</inlineCode>{`. If you get an error for either command, you need to install the program you're missing.`}</p>
    </blockquote>
    <p>{`The first thing is to make two folders in the root directory: `}<inlineCode parentName="p">{`src/`}</inlineCode>{` and `}<inlineCode parentName="p">{`dist/`}</inlineCode>{`. In the project, we'll place all the source code in the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory, and Webpack will place the built code in the `}<inlineCode parentName="p">{`dist`}</inlineCode>{` folder.`}</p>
    <h4>{`Webpack`}</h4>
    <p>{`So go on ahead and start a new npm project if you haven't already, and install Webpack at the command line:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "prompt",
        "prompt": true
      }}>{`npm init -y
npm install --save-dev webpack webpack-cli
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`Wait, what's `}<inlineCode parentName="em">{`--save-dev`}</inlineCode>{`?`}</em>{` `}<br /><inlineCode parentName="p">{`--save-dev`}</inlineCode>{` saves the installed package as a development dependency, meaning that it isn't required to `}<em parentName="p">{`run`}</em>{` the project, but it is necessary for development. In this case, once Webpack has build the project (generated a bundle), we don't need it to run the project, but if we're developing, we'll very likely need to have Webpack to rebuild the project.`}</p>
    </blockquote>
    <p>{`The `}<inlineCode parentName="p">{`webpack`}</inlineCode>{` package provides the core functionality, while `}<inlineCode parentName="p">{`webpack-cli`}</inlineCode>{` provides an interface which can be accessed through npx, npm's package executor.`}</p>
    <p>{`Webpack can be run as is, but to tune it to our needs, we should give it some configurations. So make a new file in the root directory called `}<inlineCode parentName="p">{`webpack.config.js`}</inlineCode>{`, which is the default config file that Webpack looks for.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "filepath=webpack.config.js linenumbers",
        "filepath": "webpack.config.js",
        "linenumbers": true
      }}>{`let path = require('path');
 
module.exports = {
    entry: 'src/entry.js',
    output: {
        path: path.resolve(__dirname, './dist'),
        filename: 'bundle.js',
    }
}
`}</code></pre>
    <p>{`Here, we tell Webpack to enter at the file `}<inlineCode parentName="p">{`src/entry.js`}</inlineCode>{` and to put the output bundle in `}<inlineCode parentName="p">{`dist/`}</inlineCode>{` and call it `}<inlineCode parentName="p">{`bundle.js`}</inlineCode>{`.`}</p>
    <p>{`That's all we really have to do to set up Webpack for right now. But another important thing we need to do at this point is to add an HTML file to send to the client. React is a single page application (SPA), which means that instead of sending a user new HTML every time they navigate to a new page, the change in display is controlled by Javascript. So in practice, when a client navigates to the webpage, the server sends a single HTML file with no rendered content and the Javascript needed to build and change the webpage view attached as a script. Webpack creates the Javascript bundle for us already, but we need an HTML file to send to the user which references it. It will be pretty simple: we just need an empty div element with a unique id to render our app into (if you've ever built a webpage with jQuery or ES6, you might recognize this way of doing things), and a script tag to reference the bundle.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html",
        "metastring": "filepath=dist/index.html",
        "filepath": "dist/index.html"
      }}>{`<!DOCTYPE html>
<html>
    <head>
    </head>
    <body>
        <div id="root"></div>
        <script src="./bundle.js"></script>
    </body>
</html>
`}</code></pre>
    <p>{`So now we can serve that HTML file to clients, and the bundled code will be sent along with it.`}</p>
    <h4>{`Babel`}</h4>
    <p>{`Next, install Babel and some presets we'll need to make it work:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "prompt",
        "prompt": true
      }}>{`npm install --save-dev @babel/core babel-loader @babel/preset-env @babel/preset-react
`}</code></pre>
    <p><inlineCode parentName="p">{`@babel/core`}</inlineCode>{` provides the core functionality that Babel needs to run, `}<inlineCode parentName="p">{`babel-loader`}</inlineCode>{` provides it in a Webpack loader, and `}<inlineCode parentName="p">{`@babel/preset-env`}</inlineCode>{` and `}<inlineCode parentName="p">{`@babel/preset-react`}</inlineCode>{` are transformation presets (`}<inlineCode parentName="p">{`env`}</inlineCode>{` converts ES6 to ES5, and `}<inlineCode parentName="p">{`react`}</inlineCode>{` transforms JSX into vanilla JS syntax).
Babel looks for a file called `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{` to get its configuration options. We'll keep this file really simple since we just need to tell Babel to use the transformations we installed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "filepath=.babelrc",
        "filepath": ".babelrc"
      }}>{`{
    presets: [
        "@babel/preset-env",
        "@babel/preset-react"
    ]
}
`}</code></pre>
    <p>{`Now we can use the Babel loader in Webpack. The way this is done is via defining rules, which essentially tell Webpack which loader(s) to run a file through based on regex matching with its filename. We can easily filter by filetype with `}<inlineCode parentName="p">{`$`}</inlineCode>{` to match the end of the string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript",
        "metastring": "filepath=webpack.config.js linenumbers",
        "filepath": "webpack.config.js",
        "linenumbers": true
      }}>{`module.exports = {
    entry: 'src/entry.js',
    output: {
        path: path.resolve(__dirname, './dist'),
        filename: 'bundle.js',
    },

    module: {
        rules: [
            {
                test: /\\.(js|jsx)$/,
                exclude: /node_modules/,
                use: {
                    loader: 'babel-loader'
                }
            }
        ]
    }
}
`}</code></pre>
    <p>{`Now we've told Webpack that for every file ending with `}<inlineCode parentName="p">{`.js`}</inlineCode>{` or `}<inlineCode parentName="p">{`.jsx`}</inlineCode>{` (excluding those in `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{`), it should preprocess it with Babel before bundling.`}</p>
    <h4>{`React`}</h4>
    <p>{`Now we can use ES6 and JSX freely and Babel will convert them to compatible syntax. But in order for the transpiled code to work, we need to add the missing piece to our project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash",
        "metastring": "prompt",
        "prompt": true
      }}>{`npm install --save react react-dom
`}</code></pre>
    <p><inlineCode parentName="p">{`react`}</inlineCode>{` is the React core library, and as the name might suggest, `}<inlineCode parentName="p">{`react-dom`}</inlineCode>{` provides a bridge between React and the DOM, which we need to inject our React app into the DOM in order to display it.`}</p>
    <p>{`Aaaaannnd that's it! React doesn't require any configuration out of the box. Since we've laid all the necessary groundwork, we can simply start writing code. Let's make a super simple web app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "filepath=src/entry.js",
        "filepath": "src/entry.js"
      }}>{`import React from "react"
import ReactDOM from "react-dom"
 
// import a component
import Greeting from "./greeting.js"
 
// give it some properties
const App = <Greeting color="burgundy">Hello!</Greeting>
 
// render it into the document
document.body.onload = () => {
    const root = document.getElementById('root');
    ReactDOM.render(App, root);
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "filepath=src/greeting.js",
        "filepath": "src/greeting.js"
      }}>{`import React from "react"
 
export default (props) => {
    return <p style={{color: props.color}}>{props.children}</p>
}
`}</code></pre>
    <p>{`And if we run `}<inlineCode parentName="p">{`npx webpack`}</inlineCode>{`, it'll do its thing and you'll see a `}<inlineCode parentName="p">{`bundle.js`}</inlineCode>{` file pop up in `}<inlineCode parentName="p">{`dist/`}</inlineCode>{`. If you open or serve the HTML file in a browser using your preferred method, you should see your app there in the screen!`}</p>
    <blockquote>
      <p parentName="blockquote">{`One thing you'll have to keep in mind is that to render your app into the DOM, you need to add the `}<inlineCode parentName="p">{`ReactDOM.render`}</inlineCode>{` call in a function attached to your document's `}<inlineCode parentName="p">{`onload`}</inlineCode>{` event, or some variant thereof. This ensures that the `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` element is actually in the DOM before we go trying to put stuff in it.`}</p>
    </blockquote>
    <h2>{`Next Steps`}</h2>
    <p>{`So you've got React up and running with JSX. Great! Now what? Well obviously you can start building that sick web app you've always wanted to. There are a bunch of different packages of React components which provide different functionality. For example, if you want to add different pages to your web app, you can check out `}<a parentName="p" {...{
        "href": "https://reactrouter.com"
      }}>{`react-router`}</a>{` which is the de facto routing library for React. Other cool libraries include `}<a parentName="p" {...{
        "href": "https://material-ui.com"
      }}>{`Material UI`}</a>{`, `}<a parentName="p" {...{
        "href": "https://airbnb.io/visx/"
      }}>{`VISX`}</a>{`, `}<a parentName="p" {...{
        "href": "https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/"
      }}>{`Mapbox GL`}</a>{`, and more.  Also, this project only scratches the surface of Webpack's functionality. You can add all sorts of cool things to enhance the development and deployment of your project like `}<a parentName="p" {...{
        "href": "https://webpack.js.org/loaders/css-loader/#modules"
      }}>{`CSS modules`}</a>{`, `}<a parentName="p" {...{
        "href": "https://webpack.js.org/guides/typescript/"
      }}>{`Typescript`}</a>{`, `}<a parentName="p" {...{
        "href": "https://webpack.js.org/loaders/sass-loader/"
      }}>{`SASS/SCSS`}</a>{`, `}<a parentName="p" {...{
        "href": "https://webpack.js.org/loaders/url-loader/"
      }}>{`inline images`}</a>{`, an auto-refreshing `}<a parentName="p" {...{
        "href": "https://webpack.js.org/configuration/dev-server/"
      }}>{`dev server`}</a>{` and `}<a parentName="p" {...{
        "href": "https://webpack.js.org/loaders/"
      }}>{`more`}</a>{`. Furthermore, you can explore Babel's `}<a parentName="p" {...{
        "href": "https://babeljs.io/docs/en/plugins"
      }}>{`plugin ecosystem`}</a>{` and check out things that might be useful to you. This stack provides a great toolset to build a clean and fun project, so get out there and do something cool!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      